import styled from "styled-components";
import BreakPointsEnum from "../../types/global/global";

const StyledProducts = styled.div`
    display: flex;
    flex-direction: column;

    .products__ {
        &dropdown-options-container {
            width: 300px;
        }

        &products-grid-container {
            width: 100%;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            grid-template-rows: 1fr;
            gap: 15px 15px;
        }

        &pager-container {
            width: 100%;
            height: 120px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    @media only screen and (max-width: ${BreakPointsEnum._wide}) {
        .products__ {
            &products-grid-container {
                grid-template-columns: 1fr 1fr 1fr;
            }
        }
    }
    @media only screen and (max-width: 1020px) {
        .products__ {
            &products-grid-container {
                grid-template-columns: 1fr 1fr;
            }
        }
    }
    @media only screen and (max-width: ${BreakPointsEnum._mobile}) {
        .products__ {
            &dropdown-options-container {
                width: 100%;
            }
        }
    }
`;

export default StyledProducts;
