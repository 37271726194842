import React, { PropsWithChildren } from "react";
import StyledLanding from "./Landing.styles";
import useLandingModule from "./useLandingModule";
import Carousel from "../../components/Carousel/Carousel";
import ILanding from "./Landing.types";
import LandingProduct from "../../components/LandingProduct/LandingProduct";
import ILandingProduct from "../../components/LandingProduct/LandingProduct.types";

const Landing = ({}: PropsWithChildren<ILanding>) => {
  const landingProducts = useLandingModule();

  return (
    <StyledLanding>
      <Carousel
        variant="secondary"
        items={landingProducts.map((el: ILandingProduct) => (
          <LandingProduct key={el.slug} {...el} />
        ))}
      />
    </StyledLanding>
  );
};

export default Landing;
