import styled from "styled-components";
import BreakPointsEnum from "../../types/global/global";

const StyledLandingProduct = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(100vh - 110px);
  position: relative;

  .landing-product__ {
    &description {
      text-align: left;
      position: relative;
      display: flex;
      flex-direction: column;
      z-index: 5;
      width: 70%;
      padding-left: 50px;

      & > h2 {
        margin: 0;
      }
    }

    &background {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 0;
    }

    &image {
      width: 100%;
      height: 100%;
    }
  }

  @media only screen and (max-width: ${BreakPointsEnum._desktop}) {
    .landing-product__ {
      &description {
        width: 100%;
        padding-left: 15px;
        padding-right: 15px;

        & > h2 {
          margin: 0;
        }
      }
    }
  }

  display: flex;
`;

export default StyledLandingProduct;
