import React from "react";
import StyledLandingProduct from "./LandingProduct.styles";
import ILandingProduct from "./LandingProduct.types";
import { GatsbyImage } from "gatsby-plugin-image";
import Text from "../Text/Text";
import { TagType, TagTypeEnum } from "../../types/global/fonts";
import Module from "../Module/Module";
import Button from "../Button/Button";
import { ButtonVariant, ButtonVariantEnum } from "../Button/Button.types";
import { TextVariantEnum } from "../Text/Text.types";
import { navigate } from "gatsby";

const LandingProduct = ({
  sliderDescription,
  sliderImage,
  sliderTitle,
  slug,
}: ILandingProduct) => {
  return (
    <StyledLandingProduct>
      <Module>
        <div className="landing-product__description">
          <Text as={TagTypeEnum.h2} variant={TextVariantEnum.landingTitle}>
            {sliderTitle}
          </Text>
          <Text as={TagTypeEnum.p} variant={TextVariantEnum.landingDescription}>
            {sliderDescription?.sliderDescription}
          </Text>
          <Button
            label="Order now"
            variant={ButtonVariantEnum.outlined}
            onClick={() => navigate(slug)}
          />
        </div>
      </Module>
      <div className="landing-product__background">
        <GatsbyImage
          className="landing-product__image"
          alt={sliderTitle + " picture"}
          image={sliderImage?.gatsbyImageData}
        />
      </div>
    </StyledLandingProduct>
  );
};

export default LandingProduct;
