import * as React from "react";
import Layout from "../components/Layout/Layout";
import Landing from "../modules/Landing/Landing";
import Products from "../modules/Products/Products";
import Head from "../components/Head/Head";

const IndexPage = () => {
  return (
    <Layout>
      <Head
        description="Oakfusion brand store"
        title="Oakfusion Brand Store"
        keywords="oakfusion, oakstore, brand store"
        externalScript=""
        metaImage=""
      />
      <Landing />
      <Products />
    </Layout>
  );
};

export default IndexPage;
