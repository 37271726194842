import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { translateToLink } from "../../utils/utils";

const useLandingModule = () => {
  const landingProducts = useStaticQuery(
    graphql`
      query landingSliderQuery {
        allContentfulOakProduct(filter: { isOnLandingSlider: { eq: true } }) {
          edges {
            node {
              isOnLandingSlider
              sliderTitle
              sliderImage {
                gatsbyImageData
              }
              sliderDescription {
                sliderDescription
              }
              name
            }
          }
        }
      }
    `
  );

  const landingProductsArray =
    landingProducts.allContentfulOakProduct.edges.map(
      (el: { node: { name: string } }) => {
        const slug = translateToLink(el?.node?.name);
        const objBody = el.node;
        return {
          ...objBody,
          slug,
        };
      }
    );

  return landingProductsArray;
};

export default useLandingModule;
