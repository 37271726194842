import styled from "styled-components";

const StyledPager = styled.div`
    display: flex;
    flex-direction: row;

    button {
        margin-right: 10px;
        margin-left: 10px;
    }
`;

export default StyledPager;
