import React, { PropsWithChildren, useEffect, useState } from "react";
import StyledProducts from "./Products.styles";
import Module from "../../components/Module/Module";
import { ModuleVariantEnum } from "../../components/Module/Module.types";
import SectionTitle from "../../components/SectionTitle/SectionTitle";
import DropdownInput from "../../components/Dropdown/DropdownInput";
import { DropdownInputIdEnum } from "../../components/Dropdown/DropdownInput.types";
import { DropdownOption } from "../../components/Dropdown/DropdownInput.types";
import IProducts from "./Products.types";
import { OakProductDTO } from "../../types/dto/product";
import ProductCard from "../../components/ProductCard/ProductCard";
import SearchContext, {
    useSearchContext,
} from "../../context/SearchContext/SearchContext";
import Pager from "../../components/Pager/Pager";
import { SectionTitleVariant } from "../../components/SectionTitle/SectionTitle.types";
import Text from "../../components/Text/Text";

const productsOptions: DropdownOption[] = [
    {
        label: "All",
        value: "all",
    },
    {
        label: "Clothes",
        value: "clothes",
    },
    {
        label: "Sacks",
        value: "sacks",
    },
    {
        label: "Accessories",
        value: "accessories",
    },
];

const Products = ({}: PropsWithChildren<IProducts>) => {
    const { searchContext, getPage, setCategory } = useSearchContext();

    const { primary } = ModuleVariantEnum;
    const { products } = DropdownInputIdEnum;
    const { primary: primarySectionTitle } = SectionTitleVariant;

    return (
        <Module variant={primary}>
            <StyledProducts>
                <SectionTitle variant={primarySectionTitle}>
                    OUR PRODUCTS
                </SectionTitle>
                <div className="products__dropdown-options-container">
                    <DropdownInput
                        id={products}
                        options={productsOptions}
                        onChange={setCategory}
                    />
                </div>
                <div className="products__products-grid-container">
                    {searchContext?.oakProducts?.map(
                        ({
                            node: { name, images },
                        }: {
                            node: OakProductDTO;
                        }) => (
                            <ProductCard
                                key={name}
                                name={name}
                                image={images[0]}
                            />
                        )
                    )}
                    {searchContext?.oakProducts &&
                        searchContext?.oakProducts?.length === 0 && (
                            <Text>No products found</Text>
                        )}
                </div>
                <div className="products__pager-container">
                    <Pager
                        {...searchContext}
                        previousCallback={() =>
                            getPage(searchContext.currentPage - 1)
                        }
                        nextCallback={() =>
                            getPage(searchContext.currentPage + 1)
                        }
                    />
                </div>
            </StyledProducts>
        </Module>
    );
};

export default Products;
