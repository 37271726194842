import React from "react";
import IPager from "./Pager.types";
import Button from "../Button/Button";
import ChevronLeftIcon from "../../assets/vector/chevron-left.svg";
import ChevronRightIcon from "../../assets/vector/chevron-right.svg";
import { ColorsEnum } from "../../types/global/colors";
import StyledPager from "./Pager.styles";
import Text from "../Text/Text";
import { TagType, TagTypeEnum } from "../../types/global/fonts";

const Pager = ({
    hasPrevious,
    hasNext,
    currentPage,
    itemsPerPage,
    pagesQuantity,
    previousCallback,
    nextCallback,
}: IPager) => {
    return (
        <StyledPager>
            {hasPrevious && (
                <Button
                    LeftIcon={ChevronLeftIcon}
                    iconColor={ColorsEnum.gray_10}
                    onClick={previousCallback}
                />
            )}
            <Text as={TagTypeEnum.p}>
                {` ${currentPage} of ${pagesQuantity}`}
            </Text>
            {hasNext && (
                <Button
                    RightIcon={ChevronRightIcon}
                    iconColor={ColorsEnum.gray_10}
                    label="Next"
                    onClick={nextCallback}
                />
            )}
        </StyledPager>
    );
};

export default Pager;
